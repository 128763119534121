import React from "react";
import { Grid } from "theme-ui";
import Layout from "../components/layout";
import ThemeableHeader from "../components/layout/themeable-header";
import Seo from "../components/seo";

const HomePage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <ThemeableHeader title={"Timelines"} active="/" color="coral" />
      <Grid
        as="section"
        columns={1}
        gap={[4, 5, 4, 5, 6]}
        sx={{ my: [5, 6] }}
      ></Grid>
    </Layout>
  );
};

export default HomePage;
